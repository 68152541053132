import React        from "react"
import {NewsLayout} from "sections/News/"
import useNewsData  from "shared/Hooks/News/useNewsData"
import {TextBlock}  from "src/sections/News/NewsLayout/TextBlock";
import {NEWS_2}     from "types/News"

const Article2 = () => {
  const data = useNewsData(NEWS_2);
  return (
    <NewsLayout data={data}>
      <TextBlock data={data.texts.first}/>
    </NewsLayout>
  )
}

export default Article2
